import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM_CONFIG";

const ActionTypes = {
  GetSystemConfigs: `${NAMESPACE}/GET_CONFIG_ITEMS`,
  UpdateSystemConfigs: `${NAMESPACE}/UPDATE_CONFIG_ITEMS`,
};

export const getSystemConfigs = (_, { type }) =>
  apiGet(ActionTypes.GetSystemConfigs, `system-config`, { type });

export const updateSystemConfigs = (item, { type }) =>
  apiPut(ActionTypes.GetSystemConfigs, `system-config`, {
    type,
    values: { [item.key]: item.value },
  });
