import React from "react";
import { compose } from "redux";
import { NavMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { SettingsRoutes } from "constants/routes";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const SettingsMenu = ({ hasPermission }) => {
  const canViewUsers = hasPermission(Targets.User, Actions.View);
  const canViewRoles = hasPermission(Targets.RoleTemplate, Actions.View);
  const canViewSystemConfig = hasPermission(Targets.SystemConfig, Actions.View);

  return (
    <NavMenu secondary className="secondary">
      {(canViewUsers || canViewRoles) && (
        <div className="settings-menu-header">
          <strong>User Settings</strong>
        </div>
      )}
      {canViewUsers && (
        <NavMenu.Item link={SettingsRoutes.Users}>Users</NavMenu.Item>
      )}
      {canViewRoles && (
        <NavMenu.Item link={SettingsRoutes.Templates}>Templates</NavMenu.Item>
      )}
      {canViewSystemConfig && (
        <div className="settings-menu-header">
          <strong>System Settings</strong>
        </div>
      )}
      {canViewSystemConfig && (
        <NavMenu.Item link={SettingsRoutes.DocusignConfig}>
          Docusign
        </NavMenu.Item>
      )}
    </NavMenu>
  );
};

export default compose(
  withRouter,
  withCurrentUser,
  withPermissions
)(SettingsMenu);
