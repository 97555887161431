import React from "react";
import {
  ListBuilder,
  FormBuilder,
  Form,
  ModalBuilder,
  Modal,
} from "@redriver/cinnamon";
import { Popup, Icon, Button } from "semantic-ui-react";
import {
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { getSystemConfigs, updateSystemConfigs } from "./actions";

const ListSystemConfig = ({ type, hasPermission }) => {
  let canEdit = hasPermission(Targets.SystemConfig, Actions.Edit);
  return (
    <PermissionCheck target={Targets.SystemConfig} action={Actions.View}>
      <ListBuilder
        loadAction={getSystemConfigs}
        loadParams={{ type }}
        renderList={(listData, listEvents) => (
          <div className="system-configs-list">
            {Array.isArray(listData) &&
              listData.map((item) => (
                <ConfigField
                  key={item.key}
                  item={item}
                  type={type}
                  canEdit={canEdit}
                  onSubmitted={listEvents.onReset}
                  onCancel={listEvents.onReset}
                />
              ))}
          </div>
        )}
      />
    </PermissionCheck>
  );
};

const ConfigField = ({ item, type, canEdit, onSubmitted }) => (
  <FormBuilder
    initialReadOnly={!canEdit}
    key={item.key}
    onLoad={() => ({ ...item })}
    submitAction={updateSystemConfigs}
    submitParams={{ type }}
    onSubmitted={onSubmitted}
    renderForm={(formProps, formState, formEvents) => (
      <Form {...formProps}>
        <div className="system-config-item">
          <Form.Input
            field="value"
            label={
              <div className="label">
                <label>{formProps.value.label}</label>
                <Popup
                  content={formProps.value.description}
                  trigger={<Icon name="help circle" />}
                />
              </div>
            }
            width={28}
          />
          {canEdit && (
            <React.Fragment>
              <Button
                compact
                content="Cancel"
                onClick={() => formEvents.onRefresh()}
                disabled={!formState.dirty || formState.submitting}
                loading={formState.submitting}
              />
              <ModalBuilder
                submitAction={() => formEvents.onSubmit()}
                renderTrigger={(showModal) => (
                  <Button
                    primary
                    compact
                    content="Save"
                    onClick={showModal}
                    disabled={!formState.dirty || formState.submitting}
                    loading={formState.submitting}
                  />
                )}
                renderModal={(modalProps) => (
                  <Modal.Confirmation {...modalProps}>
                    <p>Are you sure you want to change this setting?</p>
                  </Modal.Confirmation>
                )}
              />
            </React.Fragment>
          )}
        </div>
      </Form>
    )}
  />
);

export default withPermissions(ListSystemConfig);
