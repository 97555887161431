export const AppRoutes = {
  Root: "/",
  Dashboard: "/dash",
  Dealers: "/dealers",
  Schedules: "/schedules",
  CFA: "/cfa",
  Products: "/products",
  Meters: "/meters",
  PriceIncrease: "/price-increase",
  Zircon: "/zircon",
  Finance: "/finance",
  Amethyst: "/amethyst",
  Settings: "/settings",
  Customers: "/customers",
  Funders: "/funders",
  SPVs: "/spvs",
  DataUploads: "/data-uploads",
};

export const AmethystRoute = {
  Root: process.env.AMETHYST_BASE_URL,
  Sheets: process.env.AMETHYST_BASE_URL + "sheets",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  SetupUser: "/setup-user/:token?",
  VerifyEmail: "/verify-email/:token?",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token?",
  Account: "/account",
  Authenticate: "/authenticate",
};

export const ScheduleRoutes = {
  Schedule: (id) => `${ScheduleMatch(id)}`,
};

export const CFARoutes = {
  CFA: (id) => `${CFAMatch(id)}`,
};

export const ProductRoutes = {
  Product: (id = ":id") => `${AppRoutes.Products}/${id}`,
};

export const DealerRoutes = {
  Details: (id = ":id") => `${AppRoutes.Dealers}/${id}`,
  Office: (id = ":id") => `${AppRoutes.Dealers}/Offices/${id}`,
};

export const CustomerRoutes = {
  Details: (id = ":id") => `${AppRoutes.Customers}/${id}`,
};

export const ScheduleMatch = (id = ":id") => `${AppRoutes.Schedules}/${id}`;
export const CFAMatch = (id = ":id") => `${AppRoutes.CFA}/${id}`;
export const ProductMatch = (id = ":id") => `${AppRoutes.Products}/${id}`;

export const PriceIncreaseMatch = (id = ":id") =>
  `${AppRoutes.PriceIncrease}/${id}`;

export const SettingsRoutes = {
  Users: `${AppRoutes.Settings}/users`,
  Templates: `${AppRoutes.Settings}/roles`,
  DocusignConfig: `${AppRoutes.Settings}/docusign`,
  User: (id = ":id") => `${AppRoutes.Settings}/{AppRoutes.Users}/${id}`,
};

export const FinanceRoutes = {
  Customer: `${AppRoutes.Finance}/customer`,
  Supplier: `${AppRoutes.Finance}/supplier`,
  Funder: `${AppRoutes.Finance}/funder`,
};

export const FunderRoutes = {
  Funder: (id) => `${FunderMatch(id)}`,
};

export const SpvRoutes = {
  Details: (id) => `${SPVMatch(id)}`,
};

export const FunderMatch = (id = ":id") => `${AppRoutes.Funders}/${id}`;

export const SPVMatch = (id = ":id") => `${AppRoutes.SPVs}/${id}`;
