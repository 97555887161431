import React, { forwardRef } from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import AccordionHelper from "components/displays/AccordionHelper";
import PenSvg from "features/../../../shared/assets/pen.svg";
import SaveSvg from "features/../../../shared/assets/save.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import {
  getDocusignAdminRecipients,
  setDocusignAdminRecipients,
} from "../../actions";

const EDocumentSettings = forwardRef(
  ({ dealerOfficeId, toggleVisibility, collapsed }, ref) => {
    const renderReadOnly = (props) => props.value ?? "-";

    return (
      <div ref={ref} className="edocument-settings">
        <AccordionHelper
          collapsed={collapsed}
          toggleVisibility={toggleVisibility}
          title="E-Documents"
        >
          <FormBuilder
            initialReadOnly
            loadAction={getDocusignAdminRecipients}
            loadParams={dealerOfficeId}
            loadTransform={(data) => ({ recipients: data })}
            submitAction={setDocusignAdminRecipients}
            submitParams={{ dealerOfficeId }}
            renderForm={(formProps, state, events) => (
              <Form {...formProps}>
                <div className="header">
                  <div className="actions form-header">
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                    <IconButton
                      primary
                      icon={state.readOnly ? PenSvg : SaveSvg}
                      content={state.readOnly ? "Edit" : "Save"}
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                  </div>
                </div>
                <Form.Array
                  field="recipients"
                  arrayKey="id"
                  propagateUpdates="always"
                >
                  {({ arrayIndex }) => (
                    <React.Fragment>
                      {arrayIndex == 0 && <hr />}
                      <Form.Input
                        field="typeDescription"
                        label="Type"
                        inline
                        readonly
                        disabled
                      />
                      <Form.Input
                        required
                        field="name"
                        label="Name"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Email
                        required
                        field="email"
                        label="Email"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Input
                        required
                        field="phoneNumber"
                        label="Phone Number"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Input
                        required
                        field="phoneCountryCode"
                        label="Phone Country Code"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Input
                        required
                        field="position"
                        label="Position"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <hr />
                    </React.Fragment>
                  )}
                </Form.Array>
              </Form>
            )}
          />
        </AccordionHelper>
      </div>
    );
  }
);

export default EDocumentSettings;
