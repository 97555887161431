import React, { useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import { default as DocumentFeeStructure } from "./DocumentFeeStructure";
import DocumentSkuSettings from "./DocumentSkuSettings";
import EDocumentSettings from "./EDocumentSettings";
import ContractPreApprovalSettings from "./ContractPreApprovalSettings";

const DocumentSettings = ({ settingsId, dealerOfficeId }) => {
  const documentFeeStructureRef = useRef();
  const eDocumentsRef = useRef();
  const documentSkuRef = useRef();
  const contractPreApprovalRef = useRef();

  const refs = [
    { ref: documentFeeStructureRef },
    { ref: eDocumentsRef },
    { ref: documentSkuRef },
    { ref: contractPreApprovalRef },
  ];

  const [documentFeeStructureCollapsed, setDocumentFeeStructureCollapsed] =
    useState(true);
  const [eDocumentsCollapsed, setEDocumentsCollapsed] = useState(true);
  const [documentSkuCollapsed, setDocSkuCollapsed] = useState(true);
  const [contractPreApprovalCollapsed, setContractPreApprovalCollapsed] =
    useState(true);

  const focusOnTarget = (reference) => {
    const focus = refs[reference];
    focus.ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      {settingsId && (
        <Grid className="settings-grid">
          <Grid.Row className="settings-grid-nav-header">
            <Grid.Column>
              <div
                onClick={() => {
                  setDocumentFeeStructureCollapsed(
                    !documentFeeStructureCollapsed
                  );
                  setTimeout(function () {
                    focusOnTarget(0);
                  }, 1);
                }}
              >
                {"Fee's"}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setEDocumentsCollapsed(!eDocumentsCollapsed);
                  setTimeout(function () {
                    focusOnTarget(1);
                  }, 1);
                }}
              >
                E-Documents
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setDocSkuCollapsed(!documentSkuCollapsed);
                  setTimeout(() => focusOnTarget(2), 1);
                }}
              >
                Document SKU
              </div>
            </Grid.Column>
            <Grid.Column className="last-col">
              <div
                onClick={() => {
                  setDocSkuCollapsed(!contractPreApprovalCollapsed);
                  setTimeout(() => focusOnTarget(2), 1);
                }}
              >
                Contract Pre-approval
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className="flex title-bar">
              <h4 className="settings-title">Document Settings</h4>
            </div>
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <DocumentFeeStructure
              ref={documentFeeStructureRef}
              settingsId={settingsId}
              collapsed={documentFeeStructureCollapsed}
              toggleVisibility={() =>
                setDocumentFeeStructureCollapsed(!documentFeeStructureCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <EDocumentSettings
              ref={eDocumentsRef}
              dealerOfficeId={dealerOfficeId}
              collapsed={eDocumentsCollapsed}
              toggleVisibility={() =>
                setEDocumentsCollapsed(!eDocumentsCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <DocumentSkuSettings
              ref={documentSkuRef}
              settingsId={settingsId}
              collapsed={documentSkuCollapsed}
              toggleVisibility={() => setDocSkuCollapsed(!documentSkuCollapsed)}
            />
          </Grid.Row>
          {process.env.TRADE_AS_IPS && (
            <Grid.Row className="settings-grid-row">
              <ContractPreApprovalSettings
                ref={contractPreApprovalRef}
                settingsId={settingsId}
                collapsed={contractPreApprovalCollapsed}
                toggleVisibility={() =>
                  setContractPreApprovalCollapsed(!contractPreApprovalCollapsed)
                }
              />
            </Grid.Row>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DocumentSettings;
