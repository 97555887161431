import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, SettingsRoutes } from "constants/routes";
import { SideColumn, Content, ContentBuilder } from "@redriver/cinnamon";
import UserListPage from "../users/UserListPage";
import UserPage from "../users/UserPage";
import SettingsMenu from "./SettingsMenu";
import RoleListPage from "pages/roles/RoleListPage";
import { DocusignConfigPage } from "pages/system-config";

const SettingsRouter = () => {
  return (
    <Content>
      <SideColumn
        className="settings-pages"
        renderColumn={() => (
          <div>
            <SettingsMenu />
          </div>
        )}
      >
        <ContentBuilder
          renderContent={() => (
            <Switch>
              <Route path={SettingsRoutes.User()} component={UserPage} />
              <Route path={SettingsRoutes.Users} component={UserListPage} />
              <Route path={SettingsRoutes.Templates} component={RoleListPage} />
              <Route
                path={SettingsRoutes.DocusignConfig}
                component={DocusignConfigPage}
              />
              <Route render={() => <Redirect to={AppRoutes.Settings} />} />
            </Switch>
          )}
        />
      </SideColumn>
    </Content>
  );
};

export default SettingsRouter;
