import { first } from "lodash";
import {
  apiGet,
  apiPut,
  lookupReset,
  lookupRequest,
  getLookupData,
  notifySuccess,
} from "@redriver/cinnamon";
import { getRatesState } from "./selectors";
import {
  LookupTypes as SharedLookupTypes,
  getRates,
} from "features/../../../shared/features/Settings";

const NAMESPACE = "SETTINGS";

export const ActionTypes = {
  GetYieldRates: `${NAMESPACE}/GET_YIELD_RATES`,
  GetDefaultRateGroup: `${NAMESPACE}/GET_DEFAULT_RATE_GROUP`,
  SetRateGroup: `${NAMESPACE}/SET_RATE_GROUP`,
  LockYields: `${NAMESPACE}/LOCK_YIELDS`,
  UnlockYields: `${NAMESPACE}/UNLOCK_YIELDS`,
  ClearRatesState: `${NAMESPACE}/CLEAR_RATES_STATE`,
  SetRateTableFilters: `${NAMESPACE}/SET_RATE_TABLE_FILTERS`,
  SetRateType: `${NAMESPACE}/SET_RATE_TYPE`,
  SetComparisonRateType: `${NAMESPACE}/SET_COMPARISON_RATES_TYPE`,
  GetDocumentFees: `${NAMESPACE}/GET_DOCUMENT_FEES`,
  UpdateDocumentFees: `${NAMESPACE}/UPDATE_DOCUMENT_FEES`,
  UpdateFilters: `${NAMESPACE}/UPDATE_FILTERS`,
  GetDocumentSku: `${NAMESPACE}/GET_DOCUMENT_SKU`,
  SetDocumentSku: `${NAMESPACE}/SET_DOCUMENT_SKU`,
  GetDocusignAdminRecipients: `${NAMESPACE}/GET_DOCUMENT_SKU`,
  SetDocusignAdminRecipients: `${NAMESPACE}/SET_DOCUMENT_SKU`,
  GetContractPreApproval: `${NAMESPACE}/GET_CONTRACT-PRE-APPROVAL`,
  SetContractPreApproval: `${NAMESPACE}/SET_CONTRACT-PRE-APPROVAL`,
};

// Rate Groups
export const setRateGroupAsync = (data) => async (dispatch) => {
  await dispatch({
    type: ActionTypes.SetRateGroup,
    data: {
      value: data.value,
      text: (data.options.find((x) => x.value === data.value) || {}).text,
    },
  });
};

// Yield Locks
export const lockYields = (rateGroupId) =>
  apiPut(ActionTypes.LockYields, `defaults/yield-rates/${rateGroupId}/lock`);

export const unlockYields = (rateGroupId) =>
  apiPut(
    ActionTypes.UnlockYields,
    `defaults/yield-rates/${rateGroupId}/unlock`
  );

export const clearRatesState = () => (dispatch) =>
  dispatch({
    type: ActionTypes.ClearRatesState,
  });

// Rate Table types
export const getRateTableTypesLookupData = () => async (dispatch) => {
  await dispatch(lookupReset(SharedLookupTypes.RateTableTypes));
  await dispatch(lookupRequest(SharedLookupTypes.RateTableTypes));
};

export const setRateTableFilters = (rateTableType) => (dispatch, getState) => {
  // If none passed in from, get first item
  if (!rateTableType) {
    const { response } = getLookupData(
      getState(),
      SharedLookupTypes.RateTableTypes
    );
    rateTableType = Array.isArray(response) ? first(response) : null;
  }
  dispatch({ type: ActionTypes.SetRateTableFilters, rateTableType });
  dispatch(getRates(getRatesState));
};

export const setRateType = (rateType) => (dispatch) => {
  dispatch({ type: ActionTypes.SetRateType, rateType });
};

export const setComparisonRateType = (comparisonRateType) => (dispatch) => {
  dispatch({ type: ActionTypes.SetComparisonRateType, comparisonRateType });
};

//Document Fee Structure
export const getDocumentFees = (params) =>
  apiGet(
    ActionTypes.GetDocumentFees,
    "schedules/settings/document-fees",
    params
  );

export const setDocumentFees = (formData, params) => async (dispatch) => {
  let response = await dispatch(
    apiPut(ActionTypes.UpdateDocumentFees, "schedules/settings/document-fees", {
      ...formData,
      ...params,
    })
  );
  response &&
    response.success &&
    dispatch(notifySuccess("Document Fee Structure successfully updated"));
  return response;
};

export const getDocumentSku = (settingsId) =>
  apiGet(
    ActionTypes.GetDocumentSku,
    `schedules/settings/${settingsId}/document-sku`
  );
export const setDocumentSku = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.SetDocumentSku,
    `schedules/settings/${settingsId}/document-sku`,
    formData
  );

export const getDocusignAdminRecipients = (dealerOfficeId) =>
  apiGet(
    ActionTypes.GetDocusignAdminRecipients,
    `dealer-offices/${dealerOfficeId}/docusign-recipients`
  );

export const setDocusignAdminRecipients = (formData, { dealerOfficeId }) =>
  apiPut(
    ActionTypes.SetDocusignAdminRecipients,
    `dealer-offices/${dealerOfficeId}/docusign-recipients`,
    formData
  );

export const updateFilters = (filters) => async (dispatch) => {
  await dispatch({ type: ActionTypes.UpdateFilters, filters });
};

export const getContractPreApproval = (settingsId) =>
  apiGet(
    ActionTypes.GetContractPreApproval,
    `schedules/settings/${settingsId}/contract-pre-approval`
  );
export const setContractPreApproval = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.SetContractPreApproval,
    `schedules/settings/${settingsId}/contract-pre-approval`,
    formData
  );
