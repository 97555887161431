import React from "react";
import { ListBuilder, Table, ActionBar, Format } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import {
  getRateGroups,
  editRateGroup,
  removeRateGroup,
  approveRateGroup,
} from "./actions";
import SearchRateGroups from "./SearchRateGroups";
import RateGroupActions from "./RateGroupActions";
import AddRateGroupModal from "./AddRateGroupModal";
import { SortHeader } from "../../../components/tables/sortableTable";
import RateGroupStatus from "features/../../../shared/constants/enums/RateGroupStatus";
import moment from "moment-timezone";
import { SortDirection } from "features/../../../shared/constants/enums";

const ListRateGroups = ({ dealerOfficeId, searchImage, timezone }) => {
  return (
    <React.Fragment>
      <h4>Rate Groups</h4>
      <ListBuilder
        withTable
        initialFilters={{
          sortDirection: SortDirection.Desc,
          sortName: "applicableFromUtc"
        }}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={dealerOfficeId ? getRateGroups : null}
        loadParams={{ dealerOfficeId }}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list services-list">
            <ActionBar>
              <ActionBar.Item fluid>
                <SearchRateGroups
                  searchImage={searchImage}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item align="right">
                {dealerOfficeId && (
                  <AddRateGroupModal
                    dealerOfficeId={dealerOfficeId}
                    timezone={timezone}                  
                    onSubmitted={events.onRefresh}
                  />
                )}
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              compact
              dataKey="id"
              emptyMessage="No Rate Groups found"
              selectable
            >
              <Table.Column
                field="name"
                title="Rate Group Name"
                header={
                  <SortHeader
                    field="name"
                    title="Rate Group Name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="createdUtc"
                title="Created Date"
                render={(item) => {
                  const ctz = moment.utc(item.createdUtc)
                    .tz(timezone)
                    .format("DD MMM YYYY - HH:mm z");
                  return item.createdUtc ? <>{ctz}</> : "-";
                }}
                header={
                  <SortHeader
                    field="createdUtc"
                    title="Created Date"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />

              <Table.Column field="createdBy" title="Created By" />

              <Table.Column
                field="applicableFromUtc"
                title="Applicable From"
                render={(item) => {
                  const ctz = moment.utc(item.applicableFromUtc)
                    .tz(timezone)
                    .format("DD MMM YYYY - HH:mm z");
                  return item.applicableFromUtc ? <>{ctz}</> : "-";
                }}
                header={
                  <SortHeader
                    field="applicableFromUtc"
                    title="Applicable From"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />

              <Table.Column
                field="applicableToUtc"
                title="Applicable To"
                render={(item) => {
                  const ctz = moment.utc(item.applicableToUtc)
                    .tz(timezone)
                    .format("DD MMM YYYY - HH:mm z");
                  return item.applicableToUtc ? <>{ctz}</> : "-";
                }}                
              />
              <Table.Column
                field="approvedBy1"
                title="Approver 1"
                render={(item) =>
                  item.approvedBy1 ? <span>{item.approvedBy1} </span> : "-"
                }
              />
              <Table.Column
                field="approvedBy1Utc"
                title="Date & Time"
                render={(item) => {
                  const ctz = moment.utc(item.approvedBy1Utc)
                    .tz(timezone)
                    .format("DD MMM YYYY - HH:mm z");
                  return item.approvedBy1Utc ? <>{ctz}</> : "-";
                }}
              />
              <Table.Column
                field="approvedBy2"
                title="Approver 2"
                render={(item) =>
                  item.approvedBy2 ? <span>{item.approvedBy2} </span> : "-"
                }
              />
              <Table.Column
                field="approvedBy2Utc"
                title="Date & Time"
                render={(item) => {
                  const ctz = moment.utc(item.approvedBy2Utc)
                    .tz(timezone)
                    .format("DD MMM YYYY - HH:mm z");
                  return item.approvedBy2Utc ? <>{ctz}</> : "-";
                }}
              />
              <Table.Column
                field="status"
                title="Status"
                render={(item) => (
                  <span
                    className={
                      item.status === RateGroupStatus.Active
                        ? "green"
                        : item.status == RateGroupStatus.Upcoming
                        ? "red"
                        : item.status == RateGroupStatus.RequiresApproval
                        ? "orange"
                        : "grey"
                    }
                  >
                    {item.status}
                  </span>
                )}                
              />
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <RateGroupActions
                    rateGroup={item}
                    deleteAction={removeRateGroup}
                    editAction={editRateGroup}
                    approveAction={approveRateGroup}
                    onRefresh={events.onRefresh}
                    dealerOfficeId={dealerOfficeId}
                  />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default ListRateGroups;
